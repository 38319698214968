'use strict';

var $ 	   = require('jquery');

function captionPanels() {
	
	$('.caption-panel').each(function(){
		var $captionPanelHeading = $(this).findByBem('caption-panel', 'heading');
		var $info = $(this).find('.caption-panel__heading__info');
		$info.hide();
		$captionPanelHeading.mouseenter(function(){
			$info.slideDown('fast');
		});
		$captionPanelHeading.mouseleave(function(){
			$info.slideUp('fast');
		});
	});

}

module.exports = {
	init: function () {
		captionPanels();
	}
};